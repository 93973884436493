
import axios from 'axios';

import RoleService from '@/services/RoleService';
import LocalStorageService from '@/services/LocalStorageService';
import UserService from '@/services/UserService';
import { CreateOrderRequest } from '@/contracts/CreateOrderRequest';
import CalculationResults from '@/Interfaces/CalculationResults';
import TermsAndCondition from '@/components/TermsAndCondition.vue';
import PrivacyPolicy from '@/components/PrivacyPolicy.vue';

import LocationService from '@/services/LocationService';
import Location from '@/Interfaces/Location';
import { defineComponent } from 'vue';
import UserSetting from '@/Interfaces/UserSetting';

interface ComponentData {
  activateNotification: boolean;
  typeNotification: 'success' | 'error';
  titleNotification: string;
  messageNotification: string;
  locationService: LocationService;
  newOrder: CreateOrderRequest;
  calcResults: CalculationResults;
  default_product_transaction_id: string;
  localStorageService: LocalStorageService;
  userService: UserService;
  roleService: RoleService;
  isChecked: boolean;
  popoverShow: boolean;
  locationOptions: Location[];
  depositeOptions: { value: any; text: string }[];
  lowStockoptions: { value: number; text: string }[];
  selectedLocationId: string;
  disableLocationSelect: boolean;
  isCheckedNightlyEmail: boolean;
  NightlyEmailTime: string;
  isCheckedMonthlyEmail: boolean;
  isCheckedEveryPurchase: boolean;
  isCheckedCustomerPurchase: boolean;
  isCheckedPostPaymentbacktoEHR: boolean;
  isCheckedLowStockAlerts: number;
  userId: string;
  timeMinutes: string;
  timeHour: string;
  timeAmPm: string;
  return_policy: string;
  loading: boolean;
  isSetButton: boolean;
  location: Location;
  isSelectLocation: boolean;
  isSuperadminLoc: boolean;
  showTandCmodel: boolean;
  showPPModel: boolean;
  userSetting: UserSetting;
  listOfDepositAccounts: any[];
  openDefaultDepositDialog: boolean;
  selectedDefaultDepositAccount: null;
  selectedDepositAccount: null;
  moreDepositAccount: false;
  searchDefaultDeposit: string;
  filteredDepositAccounts: [];
  noResult: false;
}

export default defineComponent({
  name: 'Items',
  components: {
    TermsAndCondition,
    PrivacyPolicy,
  },
  data() {
    const data: ComponentData = {
      activateNotification: false,
      typeNotification: 'success',
      titleNotification: '',
      messageNotification: '',
      locationService: new LocationService(),
      newOrder: new CreateOrderRequest(),
      calcResults: new CalculationResults(),
      default_product_transaction_id: null,
      localStorageService: undefined,
      userService: undefined,
      roleService: undefined,
      isChecked: false,
      popoverShow: false,
      locationOptions: [],
      depositeOptions: [
        { value: null, text: 'Please select a default deposit account' },
      ],
      lowStockoptions: [
        { value: 1, text: 'Once' },
        { value: 2, text: 'Daily' },
        { value: 3, text: 'Weekly' },
      ],
      selectedLocationId: undefined,
      disableLocationSelect: false,
      isCheckedNightlyEmail: false,
      NightlyEmailTime: '',
      isCheckedMonthlyEmail: false,
      isCheckedEveryPurchase: false,
      isCheckedCustomerPurchase: false,
      isCheckedPostPaymentbacktoEHR: false,
      isCheckedLowStockAlerts: 1,
      userId: '',
      timeMinutes: '',
      timeHour: '',
      timeAmPm: 'pm',
      return_policy: '',
      loading: false,
      isSetButton: true,
      location: undefined,
      isSelectLocation: false,
      isSuperadminLoc: false,
      showTandCmodel: false,
      showPPModel: false,
      userSetting: undefined,
      listOfDepositAccounts: [],
      openDefaultDepositDialog: false,
      selectedDefaultDepositAccount: null,
      selectedDepositAccount: null,
      moreDepositAccount: false,
      searchDefaultDeposit: '',
      filteredDepositAccounts: [],
      noResult: false,
    };
    return data;
  },
  created() {
    this.localStorageService = new LocalStorageService();
    this.userService = new UserService();
    this.roleService = new RoleService(new LocalStorageService());
  },
  async mounted() {
    this.selectedLocationId = this.localStorageService.getItem(
      'primary_location_id',
    );

    this.loading = true;
    if (this.selectedLocationId) {
      await this.changeLocation();
    }

    axios
      .get(
        `${process.env.VUE_APP_BASE_URL}/deposit-account?locationId=${this.selectedLocationId}`,
      )
      .then((res) => {
        this.listOfDepositAccounts = res.data.depositAccounts;
        this.filteredDepositAccounts = this.listOfDepositAccounts;
        const defaultAccount = this.listOfDepositAccounts.find(
          (account) => account.defaultDepositAccount,
        );
        if (defaultAccount) {
          this.selectedDepositAccount = defaultAccount;
        }
      });

    this.userId = this.localStorageService.getItem('userId');
    this.calcResults.discountAmount = 0;
    this.loading = false;  
  },
  methods: {
    handleSelectedDefaultObject(data) {
      this.filteredDepositAccounts.map((item) => {
        if (item.id === data.id) {
          item.defaultDepositAccount = true;
        } else {
          item.defaultDepositAccount = false;
        }
      });
      data.defaultDepositAccount = true;
      this.selectedDefaultDepositAccount = data;
    },
    handleInput() {
      const searchQuery = this.searchDefaultDeposit.toLowerCase();
      this.filteredDepositAccounts = this.listOfDepositAccounts.filter(
        (account) =>
          account.title.toLowerCase().includes(searchQuery) ||
          account.id.toLowerCase().includes(searchQuery),
      );

      if (this.filteredDepositAccounts.length == 0) {
        this.noResult = true;
      } else {
        this.noResult = false;
      }
    },
    changeDeposit() {
      axios
        .get(
          `${process.env.VUE_APP_BASE_URL}/location?locationId=${this.selectedLocationId}&action=product_transaction&product_transaction_id=${this.default_product_transaction_id}`,
        )
        .then((res) => {
          this.activateNotification = true;
          this.typeNotification = 'success';
          this.titleNotification = 'Success';
          this.messageNotification = 'Default location set successfully';
        });
    },
    saveDefaultDeposit() {
      const payload = {
        id: this.selectedLocationId,
        name: this.location.name,
        defaultDepositAccountId: this.selectedDefaultDepositAccount.id,
      };

      axios
        .put(`${process.env.VUE_APP_BASE_URL}/deposit-account/default`, payload)
        .then((res) => {
          this.activateNotification = true;
          this.typeNotification = 'success';
          this.titleNotification = 'Success';
          this.messageNotification = 'Default deposit account set successfully';
          this.openDefaultDepositDialog = false;

          if (res.data.success) {
            const defaultAccount = this.listOfDepositAccounts.find(
              (account) => account.defaultDepositAccount,
            );
            if (defaultAccount) {
              this.selectedDepositAccount = this.selectedDefaultDepositAccount;
            }
          }

          axios
            .get(
              `${process.env.VUE_APP_BASE_URL}/deposit-account?locationId=${this.selectedLocationId}`,
            )
            .then((res) => {
              this.listOfDepositAccounts = res.data.depositAccounts;
              this.filteredDepositAccounts = this.listOfDepositAccounts;
            });
        });
    },
    changeDefaultDeposit() {
      this.moreDepositAccount = this.listOfDepositAccounts.length > 7;
      this.openDefaultDepositDialog = true;
    },
    confirmationPopupCancel() {
      this.openDefaultDepositDialog = false;
      this.searchDefaultDeposit = '';
      this.noResult = false;
      axios
        .get(
          `${process.env.VUE_APP_BASE_URL}/deposit-account?locationId=${this.selectedLocationId}`,
        )
        .then((res) => {
          this.listOfDepositAccounts = res.data.depositAccounts;
          this.filteredDepositAccounts = this.listOfDepositAccounts;
          const defaultAccount = this.listOfDepositAccounts.find(
            (account) => account.defaultDepositAccount,
          );
          if (defaultAccount) {
            this.selectedDepositAccount = defaultAccount;
          }
        });
    },
    showTandCModel() {
      this.showTandCmodel = true;
    },

    showPrivacyPolicyPModel() {
      this.showPPModel = true;
    },

    hideTandCModel() {
      this.showTandCmodel = false;
    },

    hidePrivacyPolicyPModel() {
      this.showPPModel = false;
    },

    async changeLocation() {
      if (this.selectedLocationId != '') {
        this.searchDefaultDeposit = '';
        this.noResult = false;
        axios
          .get(
            `${process.env.VUE_APP_BASE_URL}/deposit-account?locationId=${this.selectedLocationId}`,
          )
          .then((res) => {
            this.listOfDepositAccounts = res.data.depositAccounts;
            this.filteredDepositAccounts = this.listOfDepositAccounts;
            const defaultAccount = this.listOfDepositAccounts.find(
              (account) => account.defaultDepositAccount,
            );
            if (defaultAccount) {
              this.selectedDepositAccount = defaultAccount;
            }
          });
        this.localStorageService.store(
          'primary_location_id',
          this.selectedLocationId,
        );
        this.isSelectLocation = true;
        this.loading = true;
        this.isSuperadminLoc = false;
        // fetch location details by id
        const { location, userSetting } = (
          await this.locationService.getLocationById(this.selectedLocationId)
        ).data;
        this.location = location;
        this.userSetting = userSetting;
        await axios
          .get(
            `${process.env.VUE_APP_BASE_URL}/location?locationId=${this.selectedLocationId}&expand=deposite`,
          )
          .then((res) => {
            this.depositeOptions = [{ value: null, text: 'Deposit Account' }];
            this.depositeOptions = this.depositeOptions.concat(
              res.data.location.map((account: any) => {
                return { value: account.id, text: account.title };
              }),
            );
            if (this.depositeOptions.length === 2) {
              this.default_product_transaction_id =
                this.depositeOptions[1].value;
              this.location.product_transaction_id =
                this.default_product_transaction_id;
            }
          });

        this.default_product_transaction_id =
          this.location.product_transaction_id === ''
            ? null
            : this.location.product_transaction_id;
        this.isChecked = !this.location.filterByInventoryTracking;
        this.isChecked = this.location.filterByInventoryTracking;
        this.isCheckedNightlyEmail = this.userSetting?.nightlyEmail;
        this.return_policy = this.location.return_policy;
        if (
          this.userSetting?.nightlyEmail &&
          this.userSetting?.nightlyEmailTime &&
          this.userSetting?.nightlyEmailTime !== null &&
          this.userSetting?.nightlyEmailTime !== '' &&
          this.userSetting?.nightlyEmailTime !== 'null'
        ) {
          const time = this.userSetting.nightlyEmailTime.split(':');
          this.timeHour = parseInt(time[0]);
          this.timeMinutes = parseInt(time[1]);
          this.timeAmPm = time[2];
          this.isSetButton = false;
        }
        this.isCheckedLowStockAlerts = this.userSetting?.lowStockAlerts;
        this.isCheckedMonthlyEmail = this.userSetting?.monthlyEmail;
        this.isCheckedEveryPurchase = this.userSetting?.everyPurchaseEmail;
        this.isCheckedCustomerPurchase =
          this.userSetting?.customerPurchaseEmail;
        this.isCheckedPostPaymentbacktoEHR = this.location.dnp;
        this.loading = false;
      } else {
        this.isSelectLocation = false;
        this.loading = false;
      }
    },

    async changeReturnPolicy() {
      try {
        this.loading = true;
        await this.userService.updateReturnPolicy(
          this.userId,
          this.return_policy,
          this.selectedLocationId,
        );
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.activateNotification = true;
        this.typeNotification = 'error';
        this.titleNotification = 'Error';
        this.messageNotification =
          'There was an error when updating this setting, please try again.';
      }
    },

    async updateFilterMobile() {
      try {
        this.loading = true;
        await this.userService.updateFilterMobile(
          this.userId,
          this.isChecked,
          this.selectedLocationId,
        );
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.activateNotification = true;
        this.typeNotification = 'error';
        this.titleNotification = 'Error';
        this.messageNotification =
          'There was an error when updating this setting, please try again.';
      }
    },

    async changeNightlyEmail(isCheckedNightlyEmail: any) {
      try {
        let nightlyEmailTime = '';
        if (
          typeof this.timeHour === 'number' &&
          typeof this.timeMinutes === 'number'
        ) {
          nightlyEmailTime = `${this.timeHour}:${this.timeMinutes}:${this.timeAmPm}`;
          this.loading = true;
          await this.userService.updateNightlyEmail(
            this.userId,
            isCheckedNightlyEmail,
            nightlyEmailTime,
            this.selectedLocationId,
          );
          this.loading = false;
        } else {
          this.activateNotification = true;
          this.typeNotification = 'error';
          this.titleNotification = 'Error';
          this.messageNotification = 'Please select Time.';
        }
      } catch (err) {
        this.loading = false;
        this.activateNotification = true;
        this.typeNotification = 'error';
        this.titleNotification = 'Error';
        this.messageNotification =
          'There was an error when updating this setting, please try again.';
      }
    },

    async changeNightlyEmailDisable(isCheckedNightlyEmail: any) {
      try {
        const nightlyEmailTime = null;
        if (!isCheckedNightlyEmail) {
          this.loading = true;
          await this.userService.updateNightlyEmail(
            this.userId,
            isCheckedNightlyEmail,
            nightlyEmailTime,
            this.selectedLocationId,
          );
          this.timeHour = '';
          this.timeMinutes = '';
          this.timeAmPm = 'pm';
          this.loading = false;
        }
      } catch (err) {
        this.loading = false;
        this.activateNotification = true;
        this.typeNotification = 'error';
        this.titleNotification = 'Error';
        this.messageNotification =
          'There was an error when updating this setting, please try again.';
      }
    },

    async changeMonthlyEmail() {
      try {
        this.loading = true;
        await this.userService.updateMonthlyEmail(
          this.userId,
          this.isCheckedMonthlyEmail,
          this.selectedLocationId,
        );
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.activateNotification = true;
        this.typeNotification = 'error';
        this.titleNotification = 'Error';
        this.messageNotification =
          'There was an error when updating this setting, please try again.';
      }
    },

    async changeEveryPurchaseEmail() {
      try {
        this.loading = true;
        await this.userService.updateEveryPurchaseEmail(
          this.userId,
          this.isCheckedEveryPurchase,
          this.selectedLocationId,
        );
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.activateNotification = true;
        this.typeNotification = 'error';
        this.titleNotification = 'Error';
        this.messageNotification =
          'There was an error when updating this setting, please try again.';
      }
    },

    async changeCustomerPurchaseEmail() {
      try {
        this.loading = true;
        await this.userService.updateCustomerPurchaseEmail(
          this.userId,
          this.isCheckedCustomerPurchase,
          this.selectedLocationId,
        );
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.activateNotification = true;
        this.typeNotification = 'error';
        this.titleNotification = 'Error';
        this.messageNotification =
          'There was an error when updating this setting, please try again.';
      }
    },

    async changeLowStockAlerts(checked: number) {
      try {
        this.loading = true;
        await this.userService.updateLowStockAlerts(
          this.userId,
          checked,
          this.selectedLocationId,
        );
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.activateNotification = true;
        this.typeNotification = 'error';
        this.titleNotification = 'Error';
        this.messageNotification =
          'There was an error when updating this setting, please try again.';
      }
    },

    async changePostPaymentbacktoEHR() {
      try {
        this.loading = true;
        await this.userService.updateDNPforAllTransactions(
          this.userId,
          this.isCheckedPostPaymentbacktoEHR,
          this.selectedLocationId,
        );
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.activateNotification = true;
        this.typeNotification = 'error';
        this.titleNotification = 'Error';
        this.messageNotification =
          'There was an error when updating this setting, please try again.';
      }
    },
  },
});
